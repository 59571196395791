@font-face {
  font-family: "Suisse Int'l";
  src: url("../Suissefont/webfiles/SuisseIntl-Light-WebS.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: 16px;
  height: 100%;
}

@media (max-width: 760px) {
  html {
    font-size: 10px;
  }
}

@media (max-width: 460px) {
  html {
    font-size: 8px;
  }
}

.App {
  flex-wrap: wrap;
  font-family: "Suisse Int'l";
}

/* title */

h1 {
  color: var(--Lila, #322d60);
  font-size: 1.875em;
  font-style: normal;
  margin-top: 1.125em;
}

h2 {
  color: var(--Lila, #322d60);
  font-size: 0.875em;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 2.125em;
  margin-bottom: 1.5rem;
}

h3 {
  color: var(--Lila, #322d60);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 1em;
  width: fit-content;
}

h4 {
  color: var(--Lila, #322d60);
  font-style: normal;
  font-weight: 300;
  line-height: 1.5em;
  font-size: 1.125em;
  width: fit-content;
}

/* dataCollecion*/

.ScreenMargin {
  /* height: 90%; */
height: 85vh;
overflow-y: scroll;

}

/* Selection box */

.box {
  position: relative;
  width: 9.25em;
  height: 9.25em;
  flex-shrink: 0;
  border-radius: 1em;
  border: 1px solid rgba(50, 45, 96, 0.2);
  opacity: 1;
  background: var(--White, #fff);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.9375em;
}

@media (min-width: 750px) {
  div > .selectionBox:first-of-type .box {
    margin-left: 0;
  }
}

.selectionBox {
  display: inline-block;
}

.icon {
  fill: #322d60;
  opacity: 1;
}

.houseIMG {
  opacity: 1;
  max-height: 6.125em;
  max-width: 3.875em;
}

.svgContainer {
  width: 100px;
}

.hook {
  position: absolute;
  top: 1.125em;
  right: 1.125em;
  opacity: 0;
  height: 1em;
  width: 1.375em;
}

.box:hover,
.selected {
  border-color: #322d60;
}

.box:hover .hook,
.selected .hook {
  opacity: 1;
}

.iconDescription {
  text-align: center;
  margin: auto;
  color: #322d60;
  width: 9.375em;
  height: 3.75em;
}

/* next button */

.next-button {
  position: relative;
  width: auto;
  height: 3.4em;
  flex-shrink: 0;
  border: 1px solid var(--Orange, #ea4e44);
  background: var(--Orange, #ea4e44);
  color: var(--White, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

button:disabled:hover,
button[disabled]:hover {
  background-color: #e9e6ed;
  border-color: #e9e6ed;
  color: #000000;
}

button:disabled:hover .next-icon {
  filter: brightness(0);
}

.next-button:hover {
  background-color: black;
  border-color: black;
}

.next-button p {
  position: relative;
  margin-left: 1.25em;
  text-transform: uppercase;
}

.next-icon {
  width: 0.9375em;
  height: 0.9375em;
  margin-left: 1.8125em;
  margin-right: 1.4375em;
}

/* Previous button */

.previous-button {
  position: relative;
  width: auto;
  height: 3.4375em;
  flex-shrink: 0;
  border: 1px solid var(--Orange, #ea4e44);
  background: white;
  color: var(--Orange, #ea4e44);
  display: flex;
  align-items: center;
  justify-content: center;
}

.previous-button:hover {
  background-color: var(--Orange, #ea4e44);
  color: white;
}

.previous-button:hover .previous-icon {
  filter: brightness(0) invert(1);
}

.previous-button p {
  position: relative;
  margin-right: 1.25em;
  text-transform: uppercase;
}

.previous-icon {
  margin-left: 1.4375em;
  margin-right: 1.8125em;
  width: 0.9375em;
  height: 0.9375em;
}

.orange {
  filter: brightness(0) invert(1);
}

/* Person */

.PersonImg {
  padding-right: 1.25em;
  height: 3.125em;
}

/* Footer */

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  position: fixed;
  width: auto;
  left: 0;
  right: 0;
  background-color: #eeeeee;
}

.SquarePerson {
  display: grid;
  grid-template-columns: auto 3fr;
  grid-column-gap: 8.125em;
  grid-row-gap: 0.375em;
}

@media (max-width: 750px) {
  .SquarePerson {
    grid-template-columns: 1fr;
    grid-row-gap: 1em;
  }
  .footer {
    /* margin-top: 2em; */
    /* position: relative; */
  }
}

.wrapper {
  display: grid;
}

#changesText {
  width: 36.125em;
  color: #322d60;
}

/* InputRange.css */

.Slidercontainer {
  width: 17.625em;
}

.slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  margin: 1.4375em 0;
  height: 0.0625em;
  background: #322d60;
  outline: none;
  border: none;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  width: 1.6875em;
  height: 1.6875em;
  border-radius: 50%;
  background: #ea4e44;
  color: #ea4e44;
  cursor: pointer;
  border: none;
}

.slider::-moz-range-thumb {
  -moz-appearance: none;
  width: 1.6875em;
  height: 1.6875em;
  border-radius: 50%;
  background: #ea4e44;
  color: #ea4e44;
  cursor: pointer;
  border: none;
}

.range-wrap {
  width: 17.625em;
  position: relative;
}

.range-value {
  position: absolute;
  top: 90%;
}

.range-value span {
  color: #322d60;
  font-size: 1.125em;
  position: absolute;
  transform: translate(-50%, 0);
}

.TextInput {
  width: 17.625em;
}

/* Results */

#resultTitle {
  width: fit-content;
}

.underlined {
  padding-bottom: 0.625em;
  border-bottom: 2px;
  border-bottom: 0.3125em solid #ea4e44;
  width: fit-content;
}

.ResultSolution {
  display: grid;
  grid-template-columns: 12.2em 11em 4.375em;
  grid-column-gap: 1.25em;
  grid-row-gap: 0.375em;
  justify-content: center;
  position: absolute;
  text-align: center;
  top: 2.0625em;
  left: 50%;
  right: 50%;
}
@media (max-width: 760px) {
  .ResultSolution {
    position: static;
  }
}

.ResultImgWidth {
  width: 1.5em;
}

/*Bar Chart*/

.BarChart {
  justify-content: center;
}

.barChartWidth {
  width: 45.9375em;
  height: 21.875em;
}

.resultDescription {
  color: #322d60;
  margin-top: 3em;
  text-align: center;
}

.resultExplanation {
  color: #322d60;
  margin-top: 1em;
  text-align: center;
  font-size: 0.875em;
}

.OverviewBarChart {
  display: grid;
  grid-template-columns: 1.25fr 2fr;
}

@media (max-width: 760px) {
  .OverviewBarChart {
    grid-template-columns: 1fr;
  }
  .OverviewNoBr {
    justify-content: center;
  }
}

.OverviewNoBr {
  display: flex;
  align-items: center;
}

.OverviewNoBr img {
  margin-left: 0.625em;
  margin-right: 0.625em;
}

.inputWidth {
  width: 8.75em;
}

/* ScatterChart */

.scatterChartWidth {
  height: 34.688em;
  display: flex;
  justify-content: center;
}
